import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/agent/workspace/vOps_provision-documentation_dev/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { AccordionItem } from "gatsby-theme-carbon";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Accordion = makeShortcode("Accordion");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Summary`}</h1>
    <p>{`Rowa IT Aspects provides support for several common IT maintenance activities for Rowa SmartDose devices. Basic
maintenance activities such as Window Patches, Antivirus, and Remote Access ar enabled by this set of Provision
Portal configuration settings.`}</p>
    <p>{`The project and aspects that this project provides were designed and developed to be completely hands off after
an initial deployment. Once the prerequisites and installation tasks are complete, and the customer has provided
maintenance windows for OS patching down-time, all processes will then be automated and require no human interaction.`}</p>
    <p>{`The only human interaction with Provision Portal and IT Aspects after the initial prerequisites and installation steps
is remote access via Bomgar. To initiate a session, the device can be accessed via Provision Portal using the remote
access.`}</p>
    <AnchorLinks mdxType="AnchorLinks">
    <AnchorLink mdxType="AnchorLink">Reference Documentation</AnchorLink>
    <AnchorLink mdxType="AnchorLink">Prerequisites/Installation</AnchorLink>
    </AnchorLinks>
    <h2>{`Reference Documentation`}</h2>
    <p>{`To support the release of Rowa IT Aspects, several BD Software Quality Engineering processes have been executed
to formally validate the project:`}</p>
    <ol>
      <li parentName="ol">{`The overall patch process`}</li>
      <li parentName="ol">{`Maintenance window functionality for patch installation`}</li>
      <li parentName="ol">{`Bomgar Remote Access functionality`}</li>
    </ol>
    <p>{`The validation evidence can be found in the following documentation:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://bd1.sharepoint.com/sites/RowaDose728/_layouts/15/Doc.aspx?OR=teams&action=edit&sourcedoc=%7BDC8CC290-0D4A-4AA8-8FE9-0D5B3C356197%7D"
        }}>{`10000369750_v01 Rowa Dose IT Aspects SSVP`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://bd1.sharepoint.com/sites/RowaDose728/_layouts/15/Doc.aspx?OR=teams&action=edit&sourcedoc=%7B52C9AF05-76D6-44BD-9B92-B6F162418662%7D"
        }}>{`10000373881_v00 Rowa Dose IT Aspects SSVR`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://bd1.sharepoint.com/sites/RowaDose728/Shared%20Documents/General/Rowa%20Dose%20-%20IT%20Aspects/Project%20Docs/REF_ROWA%20DOSE%20IT%20aspects%20Test_Case%20SmartDose%201.6.pdf"
        }}>{`Rowa Dose IT Aspects Test Cases 1.6`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://bd1.sharepoint.com/sites/RowaDose728/Shared%20Documents/General/Rowa%20Dose%20-%20IT%20Aspects/Project%20Docs/REF_ROWA%20DOSE%20IT%20aspects%20Test_Case%20SmartDose%201.7.pdf"
        }}>{`Rowa Dose IT Aspects Test Cases 1.7`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://bd1.sharepoint.com/sites/RowaDose728/Shared%20Documents/General/Rowa%20Dose%20-%20IT%20Aspects/Project%20Docs/REF_ROWA%20DOSE%20IT%20aspects%20Test_Case_Exeuction_Report%201.6.pdf"
        }}>{`Rowa Dose IT Aspects Test Case Execution Report 1.6`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://bd1.sharepoint.com/sites/RowaDose728/Shared%20Documents/General/Rowa%20Dose%20-%20IT%20Aspects/Project%20Docs/REF_ROWA%20DOSE%20IT%20aspects%20Test_Case_Exeuction_Report%201.7.pdf"
        }}>{`Rowa Dose IT Aspects Test Case Execution Report 1.7`}</a></li>
    </ul>
    <h2>{`Prerequisites/Installation`}</h2>
    <p>{`All functionality to enable IT Aspects for Rowa devices is provided by AWS Simple Systems Manager and Provision Portal.
In order to enable a facility to utilize IT Aspects the following prerequisite actions `}<em parentName="p">{`must`}</em>{` be completed:`}</p>
    <ol>
      <li parentName="ol">{`The customer must provide an acceptable maintenance window where downtime can be tolerated once per month to install
OS patches to the devices.`}</li>
      <li parentName="ol">{`The customer facility must be on-boarded to Provision Portal`}</li>
      <li parentName="ol">{`Within the customer in Provision Portal, a deployment must be created with the various components that make up the
installation of Rowa Dose at the customer. For example, if a facility is running 1 server, 1 SmartDose, and 5 workstations
the respective quantity of components should be created in the new deployment definition.`}</li>
      <li parentName="ol">{`The BDAgent associated to the deployment must be downloaded and installed on all defined systems.`}</li>
    </ol>
    <p>{`Once the above steps have been complete, the following processes occur:`}</p>
    <Accordion mdxType="Accordion">
      <AccordionItem title="Background Operations - No Downtime" mdxType="AccordionItem">
        <ol>
          <li parentName="ol">
            <p parentName="li">{`The AWS Systems Manager agent checks for compliance policies. The ESET Antivirus compliance policy is returned and the
device immediately begins installing ESET antivirus in the back ground`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`The AWS Systems Manager performs an inventory of installed OS Patches and reports back to AWS Systems Manager`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`If the installation of the agent occurred during the maintenance windows, patched `}<em parentName="p">{`may`}</em>{` begin installing if enough time
remains in the window.`}</p>
          </li>
        </ol>
      </AccordionItem>
      <AccordionItem title="Background Operations - Possible Downtime" mdxType="AccordionItem">
        <ol>
          <li parentName="ol">{`At the time of the customer defined maintenance window, OS patches are downloaded to the device and if a reboot is
necessary after installation, it is performed.`}</li>
        </ol>
      </AccordionItem>
      <AccordionItem title="Interactive Operations" mdxType="AccordionItem">
        <p>{`When a BD Associate needs to remotely access any of the devices, they can log in to the Provision Portal, select the customer
they wish to remote to and initiate a Bomgar Session`}</p>
      </AccordionItem>
    </Accordion>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      